import { IsoDate } from '@models/common';

export const isoDateDtoMapper = (isoDate?: IsoDate | null) => {
  if (!isoDate) {
    return undefined;
  }

  try {
    return new Date(isoDate);
  } catch (_) {
    return undefined;
  }
};
