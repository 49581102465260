import numeral from 'numeral';

export const formatMoney = (value?: string | number, format = '$0,0.00') => {
  const balance = value && !Number.isNaN(value) ? Number(value) : 0;
  const isTooSmall = Math.abs(balance) < 0.000001;
  return isTooSmall ? numeral(0).format(format) : numeral(balance).format(format);
};
export const formatQuantity = (value?: string | number, format = '0,0[.][00000]') => numeral(value).format(format);
export const formatPercents = (value?: string | number, format = '0[.]00') => `${numeral(value).format(format)}%`;

export const parseMoney = (amount: string) => Number(amount.split(',').join(''));

export const format = formatMoney;
export const parse = parseMoney;

export default format;
